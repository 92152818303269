import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as styles from './article-cards-grid.module.css'
import ArticleCard from '../articleCard'

const ArticleCardsGrid = () => {
  const data = useStaticQuery(graphql`
    {
      articles: allContentfulArticle {
        nodes {
          slug
          title
          excerpt
          content {
            child: childMarkdownRemark {
              timeToRead
            }
          }
          crossPost {
            mediaOutlet {
              name
              logo {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 40
                  height: 40
                )
              }
            }
          }
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      articlePage: contentfulPage(name: { eq: "page.article" }) {
        articlePageSlug: slug
      }
    }
  `)
  const articles = data.articles.nodes
  const { articlePageSlug } = data.articlePage

  return (
    <div className={styles.articleCardsWrapper}>
      {articles.map((article) => {
        return (
          <ArticleCard
            key={article.title}
            title={article.title}
            excerpt={article.excerpt}
            image={article.image}
            slug={articlePageSlug.replace(`{{slug}}`, article.slug)}
            readTime={article.content.child.timeToRead}
            crossPost={article.crossPost}
          />
        )
      })}
    </div>
  )
}

export default ArticleCardsGrid
