import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import LearnMoreButton from '../learnMoreButton'

import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'

import * as styles from './article-card.module.css'
import Card from '../card'
import Icon from '../icons/icon'
import { getHtmlCopy, getTextCopy } from '../../helpers'

const ArticleCard = ({ title, excerpt, image, slug, readTime, crossPost }) => {
  const data = useStaticQuery(graphql`
    {
      articleCardComponent: contentfulComponent(
        name: { eq: "component.articleCard" }
      ) {
        ...componentFields
      }
    }
  `)
  const { content } = data.articleCardComponent
  const img = getImage(image)
  const bgImage = convertToBgImage(img)

  return (
    <Card className={styles.articleCard} linkTo={slug}>
      <div>
        <BackgroundImage {...bgImage}>
          <div>
            <GatsbyImage
              className={styles.articleImage}
              image={img}
              alt={`${title} cover`}
            />
          </div>
        </BackgroundImage>
      </div>
      <div className={styles.articleContent}>
        <div>
          <h3 className={styles.articleTitle}>{title}</h3>
          <p className={styles.articleSnippet}>{excerpt}</p>
        </div>

        <div>
          {crossPost && (
            <div className={styles.crossPostWrapper}>
              <GatsbyImage
                image={crossPost.mediaOutlet.logo.gatsbyImageData}
                alt={crossPost.mediaOutlet.name}
                style={{ borderRadius: 4 }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: getHtmlCopy(content, 'component.articleCard.originallyPublished')
                    .replace(`{{sourceName}}`, crossPost.mediaOutlet.name)
                    .replace(`\n`,`<br/>`),
                }}
              />
            </div>
          )}
          <footer className={styles.articleFooter}>
            <LearnMoreButton />
            <div className={styles.readTime}>
              <Icon icon="Clock" />
              {getTextCopy(content, 'component.articleCard.minRead')
                .replace(`{{readTime}}`, readTime)}
            </div>
          </footer>
        </div>
      </div>
    </Card>
  )
}

export default ArticleCard
